import { useContext, useEffect, useState } from 'react'
import './index.scss'
import emptySelect from '../../assets/not-found.svg'
import { saveAs } from 'file-saver'
// import { saveAs } from 'file-saver'
import { Button, Checkbox, Divider, Modal, Tooltip } from 'antd'
import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import getToken from '../../addTokenInterceptor'
import { DataContext } from '../../DataContext'
import { getDefaultHeaders } from '../../services/headerconstants'
import ToasterMessage from '../ToasterMessageComponent'

interface IAddFilesCallback {
  promptvalue: string
  projectid: string
  idtoken: string
  accesstoken: string
  addfilescallback: (files) => void
  kbFiles: any
  user_email: string
}

interface IKnbObj {
  file_name: string
  size: number
  links: {
    preview_url: string
    download_url: string
  }
}
const KnowledgeBaseImport: React.FC<IAddFilesCallback> = (props: IAddFilesCallback) => {
  const [plainOptions, setPlainOptions] = useState([])
  const { workshopNameData, promptData, setCurrentScreen, uuidConst } = useContext(DataContext)
  const API_URL = import.meta.env.VITE_KDD_API

  const { projectId } = getToken()
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [tempCheckedList, setTempCheckedList] = useState<string[]>([])
  let newCheckedList: string[] | ((prevState: string[]) => string[]) = []

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { sections } = useContext(DataContext)

  const showLoading = async () => {
    setOpen(true)
    setLoading(true)
    try {
      const data = {
        textprompt: promptData,
        uniq_id: uuidConst,
        templateFile: null,
        outputfiles: [{ outputtype: 'Knowledge Base' }],
        projectId,
        sections,
        workshopName: workshopNameData,
      }
      const response = await fetch(`${API_URL}/WorkshopAst/process_request/`, {
        method: 'POST',
        headers: getDefaultHeaders(props.accesstoken, props.idtoken, props.projectid),
        body: JSON.stringify(data),
      })
      if (!response?.ok) {
        const message = `An error has occurred: ${response.statusText}`
        throw new Error(message)
      } else {
        const result = await response.json()
        setPlainOptions(result)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      if (err instanceof Error) {
        setErrorMessage(err.message)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    setTempCheckedList(props.kbFiles)
  }, [props.kbFiles])
  const handleCancel = () => {
    setTempCheckedList(checkedList)
    setOpen(false)
  }
  const handleRephrase = () => {
    setTempCheckedList(checkedList)
    setOpen(false)
    setCurrentScreen('WorkshopDetails')
  }

  const handleModalOk = () => {
    newCheckedList = tempCheckedList
    props.addfilescallback(newCheckedList)
    setCheckedList(tempCheckedList)
    setOpen(false)
  }

  const handleDownload = (option: string, fileName: string) => {
    saveAs(option, `${fileName}.pptx`)
  }
  const handlePreview = (option: string) => {
    window.open(option, '_blank')
  }

  const handleCheckboxChange = (e, option: string) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setTempCheckedList([...tempCheckedList, option])
    } else {
      setTempCheckedList(tempCheckedList.filter((item) => item !== option))
    }
  }

  return (
    <>
      <div className='whole-modal'>
        <Button onClick={showLoading} className='kb-button-inside'>
          Open Knowledge Base
        </Button>
        <Modal
          className='knb-modal'
          title={<h2>Select Documents</h2>}
          footer={[
            <Button key='cancel' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key='done'
              className={'done-button'}
              type='primary'
              onClick={() => {
                if (plainOptions.length > 0) handleModalOk()
                else handleRephrase()
              }}
            >
              {plainOptions.length > 0 ? 'Done' : 'Rephrase prompt'}
            </Button>,
          ]}
          maskClosable={false}
          loading={loading}
          open={open}
          onCancel={() => setOpen(false)}
        >
          {plainOptions.length ? (
            <div className='total-container'>
              <div className='info-container'>
                <span className='info-span'>
                  Below are the top 5 Workshop Decks from the Deloitte Knowledge Base. Please select
                  one to three files as references for generating the output.
                </span>
              </div>
              <Divider />
              <div className='checkbox-group'>
                {plainOptions.map((name: IKnbObj) => {
                  const checkboxId = `checkbox-${name.file_name}`
                  return (
                    <div
                      key={name.file_name}
                      className='single-checkbox-div'
                      onChange={(e) => handleCheckboxChange(e, name.file_name)}
                    >
                      <label
                        htmlFor={checkboxId}
                        className='single-checkbox-kdd'
                        // onClick={(e) => handleCheckboxChange(e, name.file_name)}
                      >
                        <div className='check-span-container'>
                          <Checkbox
                            className='single-input custom-checkbox'
                            checked={tempCheckedList.includes(name.file_name)}
                            onChange={(e) => handleCheckboxChange(e, name.file_name)}
                            id={checkboxId}
                            disabled={
                              tempCheckedList.length === 3 &&
                              !tempCheckedList.includes(name.file_name)
                            }
                            name='section'
                            value={name}
                          />
                          <div className='span-edit-del-container-knb'>
                            <div className='name-title-container'>
                              <Tooltip
                                title={name.file_name.substring(0, name.file_name.length - 5)}
                                placement='topLeft'
                              >
                                <span className='single-label'>
                                  {name.file_name.length <= 45 ? (
                                    <>{name.file_name.substring(0, 45)}</>
                                  ) : (
                                    <>{name.file_name.substring(0, 50)}...</>
                                  )}
                                </span>
                                <span className='subheading-span'>
                                  {name.file_name.substring(15, name.file_name.length - 5).length <=
                                  45 ? (
                                    <>
                                      <span className='title-span'>Title: </span>
                                      {name.file_name.substring(15, name.file_name.length - 5)}
                                    </>
                                  ) : (
                                    <>
                                      {name.file_name.length - 15 > 75 ? (
                                        <>
                                          <span className='title-span'>Title: </span>
                                          {name.file_name.substring(15, 65)}...
                                        </>
                                      ) : (
                                        <>
                                          <span className='title-span'>Title: </span>
                                          {name.file_name.substring(15, 65)}...
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div className='buttons-container'>
                          <Button
                            className='unselect-button'
                            onClick={() => handleDownload(name.links.download_url, name.file_name)}
                          >
                            <DownloadOutlined />
                            Download
                          </Button>
                          <Button onClick={() => handlePreview(name.links.preview_url)}>
                            <ExportOutlined />
                            Preview
                          </Button>
                        </div>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className='empty-selected'>
              <div className='empty-select'>
                <img src={emptySelect} />
              </div>
              <div className='no-match-text'>No documents found</div>
              <div className='no-documents-text'>
                No matching documents found in the Deloitte Knowledge Base. Rephrase your prompt for
                better results.
              </div>
            </div>
          )}
        </Modal>
      </div>
      <ToasterMessage errorMessage={errorMessage} />
    </>
  )
}

export default KnowledgeBaseImport
