/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'

const getToken = () => {
  const { instance } = useMsal()
  const [tokenData, setTokenData] = useState({
    accessToken: '',
    idToken: '',
    projectId: '',
    user_email: '',
    user_name: '',
  })

  useEffect(() => {
    const fetchToken = async () => {
      const [account] = instance.getAllAccounts()
      if (account) {
        const accessTokenRequest = {
          scopes: ['user.read'],
          account: account,
        }
        const params = new URLSearchParams(window.location.search)
        let projectId = params.get('ascend_project_id')
        if (projectId === null) {
          projectId = ''
        }
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest)
          setTokenData({
            accessToken: accessTokenResponse.accessToken,
            idToken: accessTokenResponse.idToken,
            projectId: projectId,
            user_email: accessTokenResponse.account.username,
            user_name: accessTokenResponse.account.name!,
          })
        } catch (error) {
          console.error('Failed to acquire token silently:', error)
          // Handle error if needed
          instance.logoutRedirect()
        }
      } else {
        instance.loginRedirect()
        setTokenData({
          accessToken: '',
          idToken: '',
          projectId: '',
          user_email: '',
          user_name: '',
        })
      }
    }

    fetchToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance])

  return tokenData
}

export default getToken
