// ToasterMessage.tsx
import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

interface IToasterMessageProps {
  errorMessage: string
}

const ToasterMessage: React.FC<IToasterMessageProps> = ({ errorMessage }) => {
  const [visible, setVisible] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const key = 'updatable'

  useEffect(() => {
    if (errorMessage) {
      setVisible(true)
      showFailureMessage()
    } else {
      setVisible(false)
    }
  }, [errorMessage])

  const showFailureMessage = () => {
    const closeIcon = (
      <CloseOutlined
        style={{ color: '#fff', marginLeft: '10px' }}
        onClick={() => messageApi.destroy(key)}
      />
    )

    messageApi.open({
      key,
      content: (
        <span className='message-span'>
          <strong>{errorMessage}</strong>
          {closeIcon}
        </span>
      ),
      className: 'message',
      duration: 1.5,
    })
  }

  return <>{contextHolder}</>
}

export default ToasterMessage
